export const PENDING_STATUS_GROUP = "pending";
export const ACCEPTED_STATUS_GROUP = "accepted";
export const COMPLETED_STATUS_GROUP = "completed";
export const ABSENTED_STATUS_GROUP = "absented";

export const statusGroups = [
  {
    value: PENDING_STATUS_GROUP,
    label: "pendingAppointment",
    chipColor: "primary.main",
  },
  {
    value: ACCEPTED_STATUS_GROUP,
    label: "acceptedAppointment",
    chipColor: "success.main",
  },
  {
    value: ABSENTED_STATUS_GROUP,
    label: "absenceAppointment",
    chipColor: "error.main",
  },
  {
    value: COMPLETED_STATUS_GROUP,
    label: "completed",
    chipColor: "info.main",
  },
];
