import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { appointmentStatusApi } from "@/utils/apis";

import { AppointmentStatusActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type { FetchScope, FetchAppointmentStatusesSagaAction } from "./types";

function* fetchAppointmentStatusesSaga(
  action: FetchAppointmentStatusesSagaAction
) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "appointmentStatuses" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof appointmentStatusApi.fetchAppointmentStatuses>
    > = yield call(appointmentStatusApi.fetchAppointmentStatuses, {
      cancelToken,
    });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* appointmentStatusSaga() {
  yield all([
    takeEvery(
      AppointmentStatusActionTypes.FETCH_APPOINTMENT_STATUSES_SAGA,
      fetchAppointmentStatusesSaga
    ),
  ]);
}

export default appointmentStatusSaga;
