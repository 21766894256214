import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { appointmentApi } from "@/utils/apis";

import { AppointmentActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchScope,
  FetchPendingAppointmentsSagaAction,
  FetchAcceptedAppointmentsSagaAction,
  FetchCompletedAppointmentsSagaAction,
  FetchAbsentedAppointmentsSagaAction,
  AcceptAppointmentSagaAction,
  DeclineAppointmentSagaAction,
  RescheduleAppointmentSagaAction,
  CheckInAppointmentSagaAction,
  MarkAbsenceAppointmentSagaAction,
} from "./types";
import { storeAppointmentStatusSelectors, storeAuthSelectors } from "@/store";
import { appointmentStatusConstants } from "@/utils/constants";

function* fetchPendingAppointmentsSaga(
  action: FetchPendingAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "pendingAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const pendingGroupStatuses = statusGroup[
    appointmentStatusConstants.PENDING_STATUS_GROUP
  ]!.map((status) => status.code);
  const authUserSelectedBeautyCenter: Awaited<
    ReturnType<typeof storeAuthSelectors.selectAuthUserSelectedBeautyCenter>
  > = yield select(storeAuthSelectors.selectAuthUserSelectedBeautyCenter);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...pendingGroupStatuses],
            beauty_center_id: authUserSelectedBeautyCenter?.id!,
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isAbsented: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchAcceptedAppointmentsSaga(
  action: FetchAcceptedAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "acceptedAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const pendingGroupStatuses = statusGroup[
    appointmentStatusConstants.ACCEPTED_STATUS_GROUP
  ]!.map((status) => status.code);
  const authUserSelectedBeautyCenter: Awaited<
    ReturnType<typeof storeAuthSelectors.selectAuthUserSelectedBeautyCenter>
  > = yield select(storeAuthSelectors.selectAuthUserSelectedBeautyCenter);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...pendingGroupStatuses],
            beauty_center_id: authUserSelectedBeautyCenter?.id!,
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchCompletedAppointmentsSaga(
  action: FetchCompletedAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "completedAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const pendingGroupStatuses = statusGroup[
    appointmentStatusConstants.COMPLETED_STATUS_GROUP
  ]!.map((status) => status.code);
  const authUserSelectedBeautyCenter: Awaited<
    ReturnType<typeof storeAuthSelectors.selectAuthUserSelectedBeautyCenter>
  > = yield select(storeAuthSelectors.selectAuthUserSelectedBeautyCenter);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...pendingGroupStatuses],
            beauty_center_id: authUserSelectedBeautyCenter?.id!,
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchAbsentedAppointmentsSaga(
  action: FetchAbsentedAppointmentsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "absentedAppointments" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  const statusGroup: Awaited<
    ReturnType<
      typeof storeAppointmentStatusSelectors.selectAppointmentStatusGroup
    >
  > = yield select(
    storeAppointmentStatusSelectors.selectAppointmentStatusGroup
  );
  const pendingGroupStatuses = statusGroup[
    appointmentStatusConstants.ABSENTED_STATUS_GROUP
  ]!.map((status) => status.code);
  const authUserSelectedBeautyCenter: Awaited<
    ReturnType<typeof storeAuthSelectors.selectAuthUserSelectedBeautyCenter>
  > = yield select(storeAuthSelectors.selectAuthUserSelectedBeautyCenter);

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.fetchAppointments>> =
      yield call(appointmentApi.fetchAppointments, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            status: params?.filters?.status ?? [...pendingGroupStatuses],
            beauty_center_id: authUserSelectedBeautyCenter?.id!,
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* acceptAppointmentSaga(action: AcceptAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  const authUserSelectedBeautyCenter: Awaited<
    ReturnType<typeof storeAuthSelectors.selectAuthUserSelectedBeautyCenter>
  > = yield select(storeAuthSelectors.selectAuthUserSelectedBeautyCenter);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.acceptAppointment>> =
      yield call(appointmentApi.acceptAppointment, {
        params: {
          ...params,
          beauty_center_id: authUserSelectedBeautyCenter?.id!,
        },
        cancelToken,
      });

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* declineAppointmentSaga(action: DeclineAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  const authUserSelectedBeautyCenter: Awaited<
    ReturnType<typeof storeAuthSelectors.selectAuthUserSelectedBeautyCenter>
  > = yield select(storeAuthSelectors.selectAuthUserSelectedBeautyCenter);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.declineAppointment>> =
      yield call(appointmentApi.declineAppointment, {
        params: {
          ...params,
          beauty_center_id: authUserSelectedBeautyCenter?.id!,
        },
        cancelToken,
      });

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* rescheduleAppointmentSaga(action: RescheduleAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  const authUserSelectedBeautyCenter: Awaited<
    ReturnType<typeof storeAuthSelectors.selectAuthUserSelectedBeautyCenter>
  > = yield select(storeAuthSelectors.selectAuthUserSelectedBeautyCenter);
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.rescheduleAppointment>> =
      yield call(appointmentApi.rescheduleAppointment, {
        params: {
          ...params,
          beauty_center_id: authUserSelectedBeautyCenter?.id!,
        },
        cancelToken,
      });

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* checkInAppointmentSaga(action: CheckInAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.checkInAppointment>> =
      yield call(appointmentApi.checkInAppointment, {
        params,
        cancelToken,
      });

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* markAbsenceAppointmentSaga(action: MarkAbsenceAppointmentSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentApi.markAbsenceAppointment>> =
      yield call(appointmentApi.markAbsenceAppointment, {
        params,
        cancelToken,
      });

    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* appointmentSaga() {
  yield all([
    takeEvery(
      AppointmentActionTypes.FETCH_PENDING_APPOINTMENTS_SAGA,
      fetchPendingAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_ACCEPTED_APPOINTMENTS_SAGA,
      fetchAcceptedAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_COMPLETED_APPOINTMENTS_SAGA,
      fetchCompletedAppointmentsSaga
    ),
    takeEvery(
      AppointmentActionTypes.FETCH_ABSENTED_APPOINTMENTS_SAGA,
      fetchAbsentedAppointmentsSaga
    ),
    takeLatest(
      AppointmentActionTypes.ACCEPT_APPOINTMENT_SAGA,
      acceptAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.DECLINE_APPOINTMENT_SAGA,
      declineAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.RESCHEDULE_APPOINTMENT_SAGA,
      rescheduleAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.CHECK_IN_APPOINTMENT_SAGA,
      checkInAppointmentSaga
    ),
    takeLatest(
      AppointmentActionTypes.MARK_ABSENCE_APPOINTMENT_SAGA,
      markAbsenceAppointmentSaga
    ),
  ]);
}

export default appointmentSaga;
