import type {
  FetchAppointmentStatusesPayload,
  FetchAppointmentStatusesResponseData,
} from "@/utils/apis/appointmentStatus";

export enum AppointmentStatusActionTypes {
  FETCH_REQUESTED = "@@appointmentStatus/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@appointmentStatus/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@appointmentStatus/FETCH_FAILED",
  // Saga
  FETCH_APPOINTMENT_STATUSES_SAGA = "@@appointmentStatus/FETCH_APPOINTMENT_STATUSES_SAGA",
}

// State

export interface AppointmentStatusState {
  appointmentStatuses: FetchAppointmentStatusesResponseData["data"];
  appointmentStatusesLoading: boolean;
  appointmentStatusesError: string;
}

// ---- Reducer Action ----

export type FetchScope = "appointmentStatuses";

export type FetchRequestedAction = {
  type: AppointmentStatusActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AppointmentStatusActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AppointmentStatusState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AppointmentStatusActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchAppointmentStatusesSagaAction = {
  type: AppointmentStatusActionTypes.FETCH_APPOINTMENT_STATUSES_SAGA;
  payload?: FetchAppointmentStatusesPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AppointmentStatusAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchAppointmentStatusesSagaAction;
