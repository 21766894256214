import type {
  FetchBeautyCenterPayload,
  FetchBeautyCenterResponseData,
} from "@/utils/apis/beautyCenter";

export enum BeautyCenterActionTypes {
  FETCH_REQUESTED = "@@beautyCenter/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@beautyCenter/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@beautyCenter/FETCH_FAILED",

  FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER = "@@beautyCenter/FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER",

  // Saga
  FETCH_BEAUTY_CENTER_SAGA = "@@beautyCenter/FETCH_BEAUTY_CENTER_SAGA",
}

// State

export interface BeautyCenterState {
  hydrated?: boolean;

  beautyCenter: FetchBeautyCenterResponseData["data"] | null;
  beautyCenterLoading: boolean;
  beautyCenterError: string;
}

// ---- Reducer Action ----

export type FetchScope = "beautyCenter";

export type FetchRequestedAction = {
  type: BeautyCenterActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: BeautyCenterActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: BeautyCenterState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: BeautyCenterActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchBeautyCenterSucceededServerAction = {
  type: BeautyCenterActionTypes.FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER;
  payload: FetchBeautyCenterResponseData["data"] | null;
};

// ---- Saga Action ----

export type FetchBeautyCenterSagaAction = {
  type: BeautyCenterActionTypes.FETCH_BEAUTY_CENTER_SAGA;
  payload: FetchBeautyCenterPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type BeautyCenterAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchBeautyCenterSucceededServerAction
  //
  | FetchBeautyCenterSagaAction;
