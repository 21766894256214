import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

import { commonConfig } from "@/utils/config";

export const firebaseConfig = {
  apiKey: commonConfig.FIREBASE_API_KEY,
  authDomain: commonConfig.FIREBASE_AUTH_DOMAIN,
  projectId: commonConfig.FIREBASE_PROJECT_ID,
  storageBucket: commonConfig.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: commonConfig.FIREBASE_MESSAGING_SENDER_ID,
  appId: commonConfig.FIREBASE_APP_ID,
  measurementId: commonConfig.FIREBASE_MEASUREMENT_ID,
};

export const app = initializeApp(firebaseConfig);

let messaging;
if (typeof window !== "undefined") {
  messaging = getMessaging(app);
}
export { messaging };
