import { NotificationActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";

import type { NotificationState, NotificationAction } from "./types";

export const initialState: NotificationState = {
  readNotifications: [],
  readNotificationsLoading: true,
  readNotificationsError: "",
  readNotificationsCount: 0,

  unreadNotifications: [],
  unreadNotificationsLoading: true,
  unreadNotificationsError: "",
  unreadNotificationsCount: 0,

  notificationCountItem: null,
};

const reducer = (
  state = initialState,
  action: NotificationAction
): NotificationState => {
  switch (action.type) {
    case NotificationActionTypes.FETCH_REQUESTED: {
      const { scope, isReset, disableLoading } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
          "undefined" && !disableLoading
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
          ...(typeof state[`${scope}Count` as keyof typeof state] !==
          "undefined"
            ? {
                [`${scope}Count`]: 0,
              }
            : {}),
        });
      }

      return newState;
    }
    case NotificationActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...filteredData];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case NotificationActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case NotificationActionTypes.MARK_READ_NOTIFICATION_SUCCEEDED: {
      const { beautyCenterId } = action.payload;
      const newNotificationCountItem = !!state.notificationCountItem
        ? ({
            ...state.notificationCountItem,
            read: (state.notificationCountItem.read || 0) + 1,
            unread: (state.notificationCountItem.unread || 1) - 1,
            beauty_center: (
              state.notificationCountItem.beauty_center ?? []
            ).map((beautyCenter) => {
              if (beautyCenter.beauty_center_id === beautyCenterId) {
                return {
                  ...beautyCenter,
                  read: (beautyCenter.read || 0) + 1,
                  unread: (beautyCenter.unread || 1) - 1,
                };
              }
              return beautyCenter;
            }),
          } as typeof state.notificationCountItem)
        : null;
      return {
        ...state,
        notificationCountItem: newNotificationCountItem,
      };
    }

    case NotificationActionTypes.MARK_ALL_READ_NOTIFICATION_SUCCEEDED: {
      const { beautyCenterId } = action.payload;

      const newNotificationCountItem = !!state.notificationCountItem
        ? ({
            ...state.notificationCountItem,
            read:
              (state.notificationCountItem.read || 0) +
              (state.notificationCountItem.unread || 0),
            unread: 0,
            beauty_center: (
              state.notificationCountItem.beauty_center ?? []
            ).map((beautyCenter) => {
              if (beautyCenter.beauty_center_id === beautyCenterId) {
                return {
                  ...beautyCenter,
                  read: (beautyCenter.read || 0) + (beautyCenter.unread || 0),
                  unread: 0,
                };
              }
              return beautyCenter;
            }),
          } as typeof state.notificationCountItem)
        : null;
      return {
        ...state,
        notificationCountItem: newNotificationCountItem,
      };
    }

    case NotificationActionTypes.INCREASE_PUBLIC_UNREAD_NOTIFICATION_COUNT: {
      const { beautyCenterId } = action.payload;

      const newNotificationCountItem = !!state.notificationCountItem
        ? ({
            ...state.notificationCountItem,
            unread: (state.notificationCountItem.unread || 0) + 1,
            beauty_center: (
              state.notificationCountItem.beauty_center ?? []
            ).map((beautyCenter) => {
              if (beautyCenter.beauty_center_id === beautyCenterId) {
                return {
                  ...beautyCenter,
                  unread: (beautyCenter.unread || 0) + 1,
                };
              }
              return beautyCenter;
            }),
          } as typeof state.notificationCountItem)
        : null;
      return {
        ...state,
        notificationCountItem: newNotificationCountItem,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
