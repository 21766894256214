import { AppointmentActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchPendingAppointmentsSagaAction,
  FetchAcceptedAppointmentsSagaAction,
  FetchCompletedAppointmentsSagaAction,
  FetchAbsentedAppointmentsSagaAction,
  AcceptAppointmentSagaAction,
  DeclineAppointmentSagaAction,
  RescheduleAppointmentSagaAction,
  CheckInAppointmentSagaAction,
  MarkAbsenceAppointmentSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: AppointmentActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: AppointmentActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: AppointmentActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchPendingAppointmentsSaga = (
  payload?: FetchPendingAppointmentsSagaAction["payload"],
  meta?: FetchPendingAppointmentsSagaAction["meta"]
): FetchPendingAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_PENDING_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchAcceptedAppointmentsSaga = (
  payload?: FetchAcceptedAppointmentsSagaAction["payload"],
  meta?: FetchAcceptedAppointmentsSagaAction["meta"]
): FetchAcceptedAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_ACCEPTED_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchCompletedAppointmentsSaga = (
  payload?: FetchCompletedAppointmentsSagaAction["payload"],
  meta?: FetchCompletedAppointmentsSagaAction["meta"]
): FetchCompletedAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_COMPLETED_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const fetchAbsentedAppointmentsSaga = (
  payload?: FetchAbsentedAppointmentsSagaAction["payload"],
  meta?: FetchAbsentedAppointmentsSagaAction["meta"]
): FetchAbsentedAppointmentsSagaAction => ({
  type: AppointmentActionTypes.FETCH_ABSENTED_APPOINTMENTS_SAGA,
  payload,
  meta,
});

export const acceptAppointmentSaga = (
  payload: AcceptAppointmentSagaAction["payload"],
  meta?: AcceptAppointmentSagaAction["meta"]
): AcceptAppointmentSagaAction => ({
  type: AppointmentActionTypes.ACCEPT_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const declineAppointmentSaga = (
  payload: DeclineAppointmentSagaAction["payload"],
  meta?: DeclineAppointmentSagaAction["meta"]
): DeclineAppointmentSagaAction => ({
  type: AppointmentActionTypes.DECLINE_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const rescheduleAppointmentSaga = (
  payload: RescheduleAppointmentSagaAction["payload"],
  meta?: RescheduleAppointmentSagaAction["meta"]
): RescheduleAppointmentSagaAction => ({
  type: AppointmentActionTypes.RESCHEDULE_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const checkInAppointmentSaga = (
  payload: CheckInAppointmentSagaAction["payload"],
  meta?: CheckInAppointmentSagaAction["meta"]
): CheckInAppointmentSagaAction => ({
  type: AppointmentActionTypes.CHECK_IN_APPOINTMENT_SAGA,
  payload,
  meta,
});

export const markAbsenceAppointmentSaga = (
  payload: MarkAbsenceAppointmentSagaAction["payload"],
  meta?: MarkAbsenceAppointmentSagaAction["meta"]
): MarkAbsenceAppointmentSagaAction => ({
  type: AppointmentActionTypes.MARK_ABSENCE_APPOINTMENT_SAGA,
  payload,
  meta,
});
