import { authAxios } from "@/libs/axios";

import type {
  FetchAppointmentStatusesPayload,
  FetchAppointmentStatusesResponseData,
} from "./appointmentStatus.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const appointmentStatusApi = {
  fetchAppointmentStatuses: (payload?: FetchAppointmentStatusesPayload) => {
    return authAxios.get<
      AxiosResponseData<FetchAppointmentStatusesResponseData>
    >("appointments/setting/status/select_list", {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default appointmentStatusApi;
