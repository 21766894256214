import axios from "axios";

import { commonConfig } from "@/utils/config";

import type { AxiosError } from "axios";

const commonAxios = axios.create({
  baseURL: commonConfig.API_BASE_URL,
});

commonAxios.interceptors.request.use(
  (req) => {
    if (typeof req.headers["Locale"] === "undefined") {
      req.headers["Locale"] =
        typeof window !== "undefined"
          ? window.NextPublic.lang.toLowerCase()
          : undefined;
    }

    return req;
  },
  (err) => {
    return Promise.reject(err);
  }
);

commonAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

export default commonAxios;
