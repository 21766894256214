import { NotificationActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  MarkReadNotificationSucceededAction,
  MarkAllReadNotificationSucceededAction,
  IncreasePublicUnreadNotificationCountAction,
  // Saga
  FetchNotificationCountItemSagaAction,
  FetchReadNotificationsSagaAction,
  FetchUnreadNotificationsSagaAction,
  MarkAllReadNotificationSagaAction,
  MarkReadNotificationSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: NotificationActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: NotificationActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: NotificationActionTypes.FETCH_FAILED,
  payload,
});

export const markReadNotificationSucceeded = (
  payload: MarkReadNotificationSucceededAction["payload"]
): MarkReadNotificationSucceededAction => ({
  type: NotificationActionTypes.MARK_READ_NOTIFICATION_SUCCEEDED,
  payload,
});

export const markAllReadNotificationSucceeded = (
  payload: MarkAllReadNotificationSucceededAction["payload"]
): MarkAllReadNotificationSucceededAction => ({
  type: NotificationActionTypes.MARK_ALL_READ_NOTIFICATION_SUCCEEDED,
  payload,
});

export const increasePublicUnreadNotificationCount = (
  payload: IncreasePublicUnreadNotificationCountAction["payload"]
): IncreasePublicUnreadNotificationCountAction => ({
  type: NotificationActionTypes.INCREASE_PUBLIC_UNREAD_NOTIFICATION_COUNT,
  payload,
});

// ---- SAGA ACTION ----

export const fetchNotificationCountItemSaga = (
  payload?: FetchNotificationCountItemSagaAction["payload"],
  meta?: FetchNotificationCountItemSagaAction["meta"]
): FetchNotificationCountItemSagaAction => ({
  type: NotificationActionTypes.FETCH_NOTIFICATION_COUNT_ITEM_SAGA,
  payload,
  meta,
});

export const fetchReadNotificationsSaga = (
  payload: FetchReadNotificationsSagaAction["payload"],
  meta?: FetchReadNotificationsSagaAction["meta"]
): FetchReadNotificationsSagaAction => ({
  type: NotificationActionTypes.FETCH_READ_NOTIFICATIONS_SAGA,
  payload,
  meta,
});

export const fetchUnreadNotificationsSaga = (
  payload: FetchUnreadNotificationsSagaAction["payload"],
  meta?: FetchUnreadNotificationsSagaAction["meta"]
): FetchUnreadNotificationsSagaAction => ({
  type: NotificationActionTypes.FETCH_UNREAD_NOTIFICATIONS_SAGA,
  payload,
  meta,
});

export const markAllReadNotificationSaga = (
  payload?: MarkAllReadNotificationSagaAction["payload"],
  meta?: MarkAllReadNotificationSagaAction["meta"]
): MarkAllReadNotificationSagaAction => ({
  type: NotificationActionTypes.MARK_ALL_READ_NOTIFICATION_SAGA,
  payload,
  meta,
});

export const markReadNotificationSaga = (
  payload: MarkReadNotificationSagaAction["payload"],
  meta?: MarkReadNotificationSagaAction["meta"]
): MarkReadNotificationSagaAction => ({
  type: NotificationActionTypes.MARK_READ_NOTIFICATION_SAGA,
  payload,
  meta,
});
