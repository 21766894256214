import _groupBy from "lodash/groupBy";
import _uniq from "lodash/uniq";

import { createSelector } from "@reduxjs/toolkit";

import type { AppState } from "@/store";

export const selectAppointmentStatuses = (state: AppState) =>
  state.appointmentStatus.appointmentStatuses;

export const selectAppointmentStatusGroup = createSelector(
  selectAppointmentStatuses,
  (appointmentStatuses) => {
    const groupToStatusesMap = {} as Record<string, typeof appointmentStatuses>;

    appointmentStatuses.forEach((appointmentStatus) => {
      const groups = _uniq(appointmentStatus.group);
      groups.forEach((group) => {
        if (!groupToStatusesMap[group]) {
          groupToStatusesMap[group] = [appointmentStatus];
        } else groupToStatusesMap[group].push(appointmentStatus);
      });
    });

    return groupToStatusesMap;
  }
);
