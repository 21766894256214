import type {
  FetchNotificationsResponseData,
  FetchNotificationsPayload,
  MarkReadNotificationPayload,
  FetchNotificationCountItemResponseData,
  FetchNotificationCountItemPayload,
} from "@/utils/apis/notification";

export enum NotificationActionTypes {
  FETCH_REQUESTED = "@@notification/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@notification/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@notification/FETCH_FAILED",

  INCREASE_PUBLIC_UNREAD_NOTIFICATION_COUNT = "@@notification/INCREASE_PUBLIC_UNREAD_NOTIFICATION_COUNT",

  MARK_READ_NOTIFICATION_SUCCEEDED = "@@notification/MARK_READ_NOTIFICATION_SUCCEEDED",
  MARK_ALL_READ_NOTIFICATION_SUCCEEDED = "@@notification/MARK_ALL_READ_NOTIFICATION_SUCCEEDED",

  // Saga
  FETCH_NOTIFICATION_COUNT_ITEM_SAGA = "@@notification/FETCH_NOTIFICATION_COUNT_ITEM_SAGA",
  FETCH_READ_NOTIFICATIONS_SAGA = "@@notification/FETCH_READ_NOTIFICATIONS_SAGA",
  FETCH_UNREAD_NOTIFICATIONS_SAGA = "@@notification/FETCH_UNREAD_NOTIFICATIONS_SAGA",
  MARK_READ_NOTIFICATION_SAGA = "@@notification/MARK_READ_NOTIFICATION_SAGA",
  MARK_ALL_READ_NOTIFICATION_SAGA = "@@notification/MARK_ALL_READ_NOTIFICATION_SAGA",
}

// State

export interface NotificationState {
  readNotifications: FetchNotificationsResponseData["data"];
  readNotificationsLoading: boolean;
  readNotificationsError: string;
  readNotificationsCount: number;

  unreadNotifications: FetchNotificationsResponseData["data"];
  unreadNotificationsLoading: boolean;
  unreadNotificationsError: string;
  unreadNotificationsCount: number;

  notificationCountItem: FetchNotificationCountItemResponseData["data"] | null;
}

// ---- Reducer Action ----

export type FetchScope =
  | "readNotifications"
  | "unreadNotifications"
  | "notificationCountItem";

export type FetchRequestedAction = {
  type: NotificationActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    disableLoading?: boolean;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: NotificationActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: NotificationState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: NotificationActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type MarkReadNotificationSucceededAction = {
  type: NotificationActionTypes.MARK_READ_NOTIFICATION_SUCCEEDED;
  payload: {
    beautyCenterId: number;
  };
};

export type MarkAllReadNotificationSucceededAction = {
  type: NotificationActionTypes.MARK_ALL_READ_NOTIFICATION_SUCCEEDED;
  payload: {
    beautyCenterId: number;
  };
};

export type IncreasePublicUnreadNotificationCountAction = {
  type: NotificationActionTypes.INCREASE_PUBLIC_UNREAD_NOTIFICATION_COUNT;
  payload: {
    beautyCenterId: number;
  };
};

// ---- Saga Action ----

export type FetchReadNotificationsSagaAction = {
  type: NotificationActionTypes.FETCH_READ_NOTIFICATIONS_SAGA;
  payload: FetchNotificationsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    disableLoading?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchUnreadNotificationsSagaAction = {
  type: NotificationActionTypes.FETCH_UNREAD_NOTIFICATIONS_SAGA;
  payload: FetchNotificationsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    disableLoading?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchNotificationCountItemSagaAction = {
  type: NotificationActionTypes.FETCH_NOTIFICATION_COUNT_ITEM_SAGA;
  payload?: FetchNotificationCountItemPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type MarkReadNotificationSagaAction = {
  type: NotificationActionTypes.MARK_READ_NOTIFICATION_SAGA;
  payload: Omit<MarkReadNotificationPayload, "params"> & {
    params: {
      notification_id: number | "";
      beauty_center_id?: number | "";
    };
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type MarkAllReadNotificationSagaAction = {
  type: NotificationActionTypes.MARK_ALL_READ_NOTIFICATION_SAGA;
  payload?: Pick<MarkReadNotificationPayload, "cancelToken">;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type NotificationAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | MarkReadNotificationSucceededAction
  | MarkAllReadNotificationSucceededAction
  | IncreasePublicUnreadNotificationCountAction
  //
  | FetchNotificationCountItemSagaAction
  | FetchReadNotificationsSagaAction
  | FetchUnreadNotificationsSagaAction
  | MarkAllReadNotificationSagaAction
  | MarkReadNotificationSagaAction;
