import { BeautyCenterActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchBeautyCenterSucceededServerAction,
  // Saga
  FetchBeautyCenterSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: BeautyCenterActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: BeautyCenterActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: BeautyCenterActionTypes.FETCH_FAILED,
  payload,
});

export const fetchBeautyCenterSucceededServer = (
  payload: FetchBeautyCenterSucceededServerAction["payload"]
): FetchBeautyCenterSucceededServerAction => ({
  type: BeautyCenterActionTypes.FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchBeautyCenterSaga = (
  payload: FetchBeautyCenterSagaAction["payload"],
  meta?: FetchBeautyCenterSagaAction["meta"]
): FetchBeautyCenterSagaAction => ({
  type: BeautyCenterActionTypes.FETCH_BEAUTY_CENTER_SAGA,
  payload,
  meta,
});
