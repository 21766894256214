import type {
  AcceptAppointmentPayload,
  CheckInAppointmentPayload,
  DeclineAppointmentPayload,
  FetchAppointmentsPayload,
  FetchAppointmentsResponseData,
  MarkAbsenceAppointmentPayload,
  RescheduleAppointmentPayload,
} from "@/utils/apis/appointment";

export enum AppointmentActionTypes {
  FETCH_REQUESTED = "@@appointment/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@appointment/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@appointment/FETCH_FAILED",

  // Saga
  FETCH_PENDING_APPOINTMENTS_SAGA = "@@appointment/FETCH_PENDING_APPOINTMENTS_SAGA",
  FETCH_ACCEPTED_APPOINTMENTS_SAGA = "@@appointment/FETCH_ACCEPTED_APPOINTMENTS_SAGA",
  FETCH_COMPLETED_APPOINTMENTS_SAGA = "@@appointment/FETCH_COMPLETED_APPOINTMENTS_SAGA",
  FETCH_ABSENTED_APPOINTMENTS_SAGA = "@@appointment/FETCH_ABSENTED_APPOINTMENTS_SAGA",
  ACCEPT_APPOINTMENT_SAGA = "@@appointment/ACCEPT_APPOINTMENT_SAGA",
  DECLINE_APPOINTMENT_SAGA = "@@appointment/DECLINE_APPOINTMENT_SAGA",
  RESCHEDULE_APPOINTMENT_SAGA = "@@appointment/RESCHEDULE_APPOINTMENT_SAGA",
  CHECK_IN_APPOINTMENT_SAGA = "@@appointment/CHECK_IN_APPOINTMENT_SAGA",
  MARK_ABSENCE_APPOINTMENT_SAGA = "@@appointment/MARK_ABSENCE_APPOINTMENT_SAGA",
}

// State

export interface AppointmentState {
  pendingAppointments: FetchAppointmentsResponseData["data"];
  pendingAppointmentsLoading: boolean;
  pendingAppointmentsError: string;
  pendingAppointmentsCount: number;

  acceptedAppointments: FetchAppointmentsResponseData["data"];
  acceptedAppointmentsLoading: boolean;
  acceptedAppointmentsError: string;
  acceptedAppointmentsCount: number;

  absentedAppointments: FetchAppointmentsResponseData["data"];
  absentedAppointmentsLoading: boolean;
  absentedAppointmentsError: string;
  absentedAppointmentsCount: number;

  completedAppointments: FetchAppointmentsResponseData["data"];
  completedAppointmentsLoading: boolean;
  completedAppointmentsError: string;
  completedAppointmentsCount: number;
}

// ---- Reducer Action ----

export type FetchScope =
  | "pendingAppointments"
  | "acceptedAppointments"
  | "completedAppointments"
  | "absentedAppointments";

export type FetchRequestedAction = {
  type: AppointmentActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AppointmentActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AppointmentState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AppointmentActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchPendingAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_PENDING_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchAcceptedAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_ACCEPTED_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchCompletedAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_COMPLETED_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchAbsentedAppointmentsSagaAction = {
  type: AppointmentActionTypes.FETCH_ABSENTED_APPOINTMENTS_SAGA;
  payload?: FetchAppointmentsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AcceptAppointmentSagaAction = {
  type: AppointmentActionTypes.ACCEPT_APPOINTMENT_SAGA;
  payload: Omit<AcceptAppointmentPayload, "params"> & {
    params: Omit<AcceptAppointmentPayload["params"], "beauty_center_id">;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeclineAppointmentSagaAction = {
  type: AppointmentActionTypes.DECLINE_APPOINTMENT_SAGA;
  payload: Omit<DeclineAppointmentPayload, "params"> & {
    params: Omit<DeclineAppointmentPayload["params"], "beauty_center_id">;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type RescheduleAppointmentSagaAction = {
  type: AppointmentActionTypes.RESCHEDULE_APPOINTMENT_SAGA;
  payload: Omit<RescheduleAppointmentPayload, "params"> & {
    params: Omit<RescheduleAppointmentPayload["params"], "beauty_center_id">;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CheckInAppointmentSagaAction = {
  type: AppointmentActionTypes.CHECK_IN_APPOINTMENT_SAGA;
  payload: CheckInAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type MarkAbsenceAppointmentSagaAction = {
  type: AppointmentActionTypes.MARK_ABSENCE_APPOINTMENT_SAGA;
  payload: MarkAbsenceAppointmentPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type AppointmentAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchPendingAppointmentsSagaAction
  | FetchAcceptedAppointmentsSagaAction
  | FetchCompletedAppointmentsSagaAction
  | FetchAbsentedAppointmentsSagaAction
  | AcceptAppointmentSagaAction
  | DeclineAppointmentSagaAction
  | RescheduleAppointmentSagaAction
  | CheckInAppointmentSagaAction
  | MarkAbsenceAppointmentSagaAction;
