import { FetchAuthUserResponseData } from "@/utils/apis/auth";
import { storageService } from "..";
import { storageKeyConstants } from "@/utils/constants";

import type { OptionsType } from "cookies-next/lib/types";

export const getCookieAuthUserId = (options?: OptionsType) => {
  return (
    storageService.getCookieItem<number>(
      storageKeyConstants.AUTH_USER_ID,
      options
    ) ?? null
  );
};
export const destroyCookieAuthUserId = (options?: OptionsType) => {
  storageService.destroyCookieItem(storageKeyConstants.AUTH_USER_ID, options);
};
export const saveCookieAuthUserId = (
  userId: number | null,
  options?: OptionsType
) => {
  storageService.saveCookieItem(
    storageKeyConstants.AUTH_USER_ID,
    userId,
    options
  );
};

export const getCookieAuthUserSelectedBeautyCenterId = (
  options?: OptionsType
) => {
  return (
    storageService.getCookieItem<number>(
      storageKeyConstants.AUTH_USER_SELECTED_BEAUTY_CENTER_ID,
      options
    ) ?? null
  );
};
export const destroyCookieAuthUserSelectedBeautyCenterId = (
  options?: OptionsType
) => {
  storageService.destroyCookieItem(
    storageKeyConstants.AUTH_USER_SELECTED_BEAUTY_CENTER_ID,
    options
  );
};
export const saveCookieAuthUserSelectedBeautyCenterId = (
  beautyCenterId: number | null,
  options?: OptionsType
) => {
  storageService.saveCookieItem(
    storageKeyConstants.AUTH_USER_SELECTED_BEAUTY_CENTER_ID,
    beautyCenterId,
    options
  );
};

export const getAuthUserSelectedBeautyCenter = () => {
  return (
    storageService.getLocalItem<
      FetchAuthUserResponseData["data"]["beauty_centers"][number]
    >(storageKeyConstants.AUTH_USER_SELECTED_BEAUTY_CENTER) ?? null
  );
};
export const destroyAuthUserSelectedBeautyCenter = () => {
  storageService.destroyLocalItem(
    storageKeyConstants.AUTH_USER_SELECTED_BEAUTY_CENTER
  );
  destroyCookieAuthUserSelectedBeautyCenterId();
};
export const saveAuthUserSelectedBeautyCenter = (
  selectedBeautyCenter: Partial<
    FetchAuthUserResponseData["data"]["beauty_centers"][number]
  > | null
) => {
  storageService.saveLocalItem(
    storageKeyConstants.AUTH_USER_SELECTED_BEAUTY_CENTER,
    selectedBeautyCenter
  );
  saveCookieAuthUserSelectedBeautyCenterId(selectedBeautyCenter?.id ?? null);
};

export const saveCookieLocale = (locale: string, options?: OptionsType) => {
  storageService.saveCookieItem(storageKeyConstants.APP_LOCALE, locale, {
    ...options,
  });
};
