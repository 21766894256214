import { all, fork } from "redux-saga/effects";

import auth from "./auth/sagas";
import appointment from "./appointment/sagas";
import appointmentStatus from "./appointmentStatus/sagas";
import beautyCenter from "./beautyCenter/sagas";
import notification from "./notification/sagas";
import common from "./common/sagas";

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(appointment),
    fork(appointmentStatus),
    fork(beautyCenter),
    fork(notification),
    fork(common),
  ]);
}
