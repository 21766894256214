import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth/reducer";
import common from "./common/reducer";
import appointment from "./appointment/reducer";
import appointmentStatus from "./appointmentStatus/reducer";
import beautyCenter from "./beautyCenter/reducer";
import notification from "./notification/reducer";

const rootReducer = combineReducers({
  auth,
  common,
  appointment,
  appointmentStatus,
  beautyCenter,
  notification,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
