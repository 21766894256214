import { authAxios } from "@/libs/axios";

import type {
  FetchProductServicesPayload,
  FetchProductServicesResponseData,
} from "./productService.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const productServiceApi = {
  fetchProductServices: (payload?: FetchProductServicesPayload) => {
    return authAxios.get<AxiosResponseData<FetchProductServicesResponseData>>(
      "product_services/select_list",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default productServiceApi;
