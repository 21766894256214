import { authAxios } from "@/libs/axios";

import type {
  FetchBeautyCenterPayload,
  FetchBeautyCenterResponseData,
} from "./beautyCenter.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const beautyCenterApi = {
  fetchBeautyCenter: (payload: FetchBeautyCenterPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchBeautyCenterResponseData>>(
      `beauty_centers/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
};

export default beautyCenterApi;
